import { graphql, Link } from "gatsby";
import * as React from "react";
import { CONSTANTS } from "../components/constants";
import Image from "../components/image";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import Seo from "../components/seo";

export const infographicsPageQuery = graphql`
  query infographicsPageData($strapiIds: [Int]!) {
    infographics: allStrapiInfographics(
      sort: { order: ASC, fields: published_at }
      filter: { strapiId: { in: $strapiIds } }
    ) {
      nodes {
        images: childrenFile {
          url
          childImageSharp {
            gatsbyImageData(
              width: 590
              quality: 90
              aspectRatio: 1
              transformOptions: { cropFocus: ENTROPY, trim: 20 }
            )
          }
        }
        slug
        title
        strapiId
      }
      totalCount
    }
  }
`;

const Infographics = ({ pageContext, data }) => {
  return (
    <Layout altBg={true}>
      <Seo
        title="Research"
        // description= TODO: Conversations SEO description!
        meta={[
          {
            property: `og:url`,
            content: `${CONSTANTS.URL}/research/`,
          },
        ]}
      />
      <div className="section">
        <div className="row gtr-150 gtr-uniform">
          {data.infographics.nodes.map((infog) => (
            <div className="col-4 col-6-medium" key={infog.strapiId}>
              <span className="image fit overlay">
                <Link to={`/research/${infog.slug}`}>
                  {infog.images.length > 0 ? (
                    <Image image={infog.images[0]} />
                  ) : (
                    //   No image place holder!
                    <>
                      <div
                        data-gatsby-image-wrapper=""
                        className="gatsby-image-wrapper"
                      >
                        <div
                          aria-hidden="true"
                          style={{
                            paddingTop: "100%",
                            backgroundColor: "lightsteelblue",
                          }}
                        ></div>
                      </div>
                    </>
                  )}
                </Link>
              </span>
              <div className="item-title">
                <h3>{infog.title}</h3>
              </div>
            </div>
          ))}
        </div>
        {pageContext.totalCount > 9 && (
          <div className="row aln-center xlarge-top-margin">
            <Pagination
              {...{
                url: `/research/page-`,
                totalCount: pageContext.totalCount,
                pageSize: 9,
                siblingCount: 1,
                currentPage: pageContext.page,
                firstPageSpecial: "/research/",
              }}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Infographics;
